import clsx from 'clsx'
import Navbar from '@/components/Navbar'
import styles from './not-found.module.scss'
import { Metadata } from 'next'
import Particles from '@/components/Particles'

const NOTFOUND_TEXT = `Our server's playing hide and seek and this page is winning.`

export default function NotFound() {
  return (
    <div className={clsx(styles.page, 'background__radical')}>
      <Navbar background="background__radical" />
      <section className={styles.page__content}>
        <h1 className={styles.page__heading}>{NOTFOUND_TEXT}</h1>
      </section>
      <Particles />
    </div>
  )
}

export async function generateMetadata(): Promise<Metadata> {
  return {
    metadataBase: new URL(process.env.NEXT_PUBLIC_APP_URL || "https://www.nationaldigital.com.au"),
    title: `404 | ${NOTFOUND_TEXT}`,
    description: NOTFOUND_TEXT,
  }
}
